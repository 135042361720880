



































































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { ISiteService, SiteServiceType } from '../../../../../contexts'

import { StructureConfigurable } from '../../../../../support/mixins'
import { Inject } from '../../../../../support'

import { Form } from '../../../../shared/molecules/Form'
import { IProfileService, ProfileServiceType } from '../../../../profile/contracts'

import { RouteNames as AuthRouteNames } from '../../../routes'
import { SSOAuthMixin, UserModel } from '../../../shared'

import { signupValidationsMap } from '../SignupForm.helpers'
import { SignupFormFormData } from '../SignupForm.contracts'
import Consents from '../../../../shared/molecules/Consents/Consents.vue'
import { defaultComponentConfig, SIGNUP_FORM_COMPONENT_KEY } from '../Signup.config'

import SocialsLogin from '../partials/SocialsLogin/SocialsLogin.vue'
import SignupBaseMixin from '../Signup.mixin'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SignupSimplified>({
  name: 'SignupSimplified',
  components: { Consents, Form, SocialsLogin, VClamp: () => import(/* webpackChunkName: "vue-clamp" */ 'vue-clamp') },
  created (): void {
    this.config = this.getComponentConfig(SIGNUP_FORM_COMPONENT_KEY, defaultComponentConfig)
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
    for (const consent of this.consentsOptions) {
      this.payload[consent.option] = false
    }
  }
})
export class SignupSimplified extends Mixins(SignupBaseMixin, SSOAuthMixin, StructureConfigurable) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly consents!: AnyObject

  @Prop({ type: Boolean, required: false, default: () => false })
  public readonly isAlertUnderForm!: AnyObject

  public expandedGroup: string[] = []

  public isGDPRExpanded: boolean = false

  public pages: Record<string, string> | null = null
  public validatorsMap = signupValidationsMap

  public get checkAll (): boolean {
    return this.consentsOptions.every((key: AnyObject) => {
      return typeof this.payload[key.option] !== 'undefined' && this.payload[key.option]
    })
  }

  public set checkAll (checked: boolean) {
    for (const consent of this.consentsOptions) {
      this.payload = {
        ...this.payload,
        [consent.option]: checked
      }
    }
  }

  /**
   * Default values for payload.
   */
  public payload: SignupFormFormData = {
    email: '',
    password: '',
    passwordConfirmation: '',
    subscribeToNews: false
  } as SignupFormFormData

  /**
   * TODO.
   */
  public get consentsOptions (): AnyObject[] {
    return this.consents.options
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }
  }

  /**
   * Handles @success event of `Form`
   */
  public onFail (error: Error): void {
    this.setError(error)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.$emit('onSuccess')
  }

  /**
   * Process signup with given payload.
   */
  public async signup (): Promise<void> {
    this.cleanError()
    try {
      const tokenModel = await this.authControl.signup(this.payload as SignupFormFormData)

      this.$store.commit('auth/SET_REDIRECT_TO', { name: `auth.${AuthRouteNames.SignUpWelcome}` })

      this.eventBus.emit(
        'app:authorization.sign_up',
        {
          email: this.payload.email,
          firstName: this.payload.firstName,
          lastName: this.payload.lastName,
          emailOffers: this.payload.emailOffers,
          smsOffers: this.payload.smsOffers
        }
      )

      if (this.payload.emailOffers || this.payload.smsOffers) {
        this.eventBus.emit('app:newsletter.consents', {
          email: this.payload.email,
          firstName: this.payload.firstName,
          lastName: this.payload.lastName,
          emailOffers: this.payload.emailOffers,
          smsOffers: this.payload.smsOffers
        })
      }

      this.authService.setToken(tokenModel.toAuthToken())
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }
}

export default SignupSimplified
